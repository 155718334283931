body,
html {
    font-size: 16px;
    font-family: proxima-nova, sans-serif;
    font-weight: 400;
    font-size: 16.5px;
    color: #333;
    overscroll-behavior-block:inherit;
   overflow-x: hidden;
}

.banner {
    position: relative;
}

.banner .owl-prev,
.banner .owl-next {
    height: 110px;
    width: 110px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    background: transparent !important;
    transition: .3s all ease;
}

.banner .owl-prev {
    left: 6px;
}

.banner .owl-next {
    right: -60px;
}

.banner .owl-prev::before,
.banner .owl-next::before {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 80px;
    background: gray transparent;
    content: '';
    border-radius: 50%;
}

.banner .owl-prev::before {
    left: 44px;
}

.banner .owl-next::before {
    right: -106px;
}

.banner .owl-prev i,
.banner .owl-next i {
    font-size: 20px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.banner .owl-next i {
    right: 0%;
}

.banner .owl-prev:hover::before,
.banner .owl-next:hover::before {
    background: #1e3669;
}

.zoom {
    transition: transform .2s;
    /* Animation */
    margin: 0 auto;
    display: block;
    overflow: hidden;
}

.zoom:hover {
    -ms-transform: scale(1);
    /* IE 9 */
    -webkit-transform: scale(0.1);
    /* Safari 3-8 */
    transform: scale(1.1);
}

.clinticonzoom {
    transition: transform .5s;
    /* Animation */
    margin: 0 auto;
}

.clinticonzoom:hover {
    opacity: 75%;
    transform: scale(1.2);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}


/*================================================
20 - FOOTER AREA CSS
==================================================*/

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    color: inherit
}

a:focus {
    text-decoration: none;
    outline: medium none;
    color: inherit
}

a:hover {
    color: inherit;
    text-decoration: none;
    color: inherit
}

ul,
ol {
    margin: 0;
    padding: 0;
}

li {
    list-style-type: none;
}

.table {
    margin-bottom: 0
}

.section_100 {
    padding: 100px 0;
}

.section_50 {
    padding: 15px 0;
}

.section_15 {
    padding: 15px 0
}

.section_10 {
    padding: 10px 0
}

.pad-right {
    padding-right: 0
}

.section_t_100 {
    padding-top: 100px
}

.section_b_70 {
    padding-bottom: 70px
}

.section_t_70 {
    padding-top: 70px
}

.section_b_100 {
    padding-bottom: 100px
}


@media (min-width:576px) {
    .container {
        max-width: 540px
    }
}

@media (min-width:768px) {
    .container {
        max-width: 750px
    }
}

@media (min-width:992px) {
    .container {
        max-width: 970px
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1170px
    }
}

@media (min-width: 1400px) {
    .boxed {
        padding: 0 50px;
    }
}

@media (max-width: 991px) {
    .boxed {
        padding: 0;
    }
}

.construct-footer-top-area {
    background: #263238 none repeat scroll 0 0;
    color: white;
}

.single-footer {
    margin: 0
}

.footer-social-icon {
    margin-top: 30px;
}

.footer-social {
    margin-top: 20px
}

.footer-social>li {
    display: inline-block;
}

.footer-social li a {
    background-color: white;
    border-radius: 50%;
    color: #777;
    display: block;
    font-size: 18px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    width: 40px;
}

#fb:hover {
    background: #4b6ea9;
    color: #fff;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
}

#twitter:hover {
    background: #65bbf2 none repeat scroll 0 0;
    color: #fff;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
}

#gp:hover {
    background: #dd4d41 none repeat scroll 0 0;
    color: #fff;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
}

#linkedin:hover {
    background: #0075b5 none repeat scroll 0 0;
    color: #fff;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
}

.single-footer>h3,
.footer-social-icon>h3 {
    color: #eee;
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 15px;
    margin-top: 15px;
    text-transform: uppercase;
}

.footer-widget>li {
    border-bottom: 1px dashed #999;
}

.footer-widget>li:last-child {
    border-bottom: 0px dashed #999;
}

.footer-widget a {
    display: block;
    padding: 5px 0;
    text-transform: capitalize;
    text-decoration: none;
}

.footer-widget a:hover {
    color: #fff;
    padding-left: 5px
}

.contact-details i {
    color: #fff;
    margin-right: 10px;
}

.contact-details {
    margin: 6px 0;
}

.insta-post {
    display: inline-block;
    height: 65px;
    margin: 2px;
    width: 65px;
    position: relative
}

.overlay-insta {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: rgba(23, 23, 23, 0.8) none repeat scroll 0 0;
    cursor: pointer;
    height: 100%;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: absolute;
    top: 0;
    -webkit-transform: perspective(500px) rotateY(-90deg);
    transform: perspective(500px) rotateY(-90deg);
    -webkit-transition: all 0.4s linear 0s;
    transition: all 0.4s linear 0s;
    visibility: hidden;
    width: 100%;
}

.insta-post:hover .overlay-insta {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transform: perspective(500px) rotateY(0deg);
    transform: perspective(500px) rotateY(0deg);
    visibility: visible;
}

.construct-footer-bottom-area {
    background: #263238 none repeat scroll 0 0;
    color: rgb(250, 250, 250);
}

.copyright-left {
    text-align: left;
    font-weight: 400
}

.copyright-right {
    text-align: right;
    font-weight: 400
}

.copyright-right>p>i {
    color: #fbb908;
    margin: 0px 5px;
    font-weight: 600
}

.copyright-right>p {
    text-transform: capitalize;
}

.copyright-right>p>span {
    color: #fbb908;
    font-weight: 700;
    margin: 0 5px;
    text-transform: capitalize;
}

.footer-inner {
    display: inline-block;
    margin-top: 0.625rem;
    line-height: 2;
}

.footerliststyle {
    font-size: 15px;
}

.footermainli {
    font-weight: bold;
    height: 40px;
}


/* OUR TEAM CSS */

.team4 {
    font-family: "Montserrat", sans-serif;
    color: #8d97ad;
    font-weight: 300;
}

.team4 h1,
.team4 h2,
.team4 h3,
.team4 h4,
.team4 h5,
.team4 h6 {
    color: #3e4555;
}

.team4 .font-weight-medium {
    font-weight: 500;
}

.team4 h5 {
    line-height: 22px;
    font-size: 18px;
}

.team4 .subtitle {
    color: black;
    line-height: 24px;
    font-size: 16px;
}

.team4 ul li a {
    color: #8d97ad;
    padding-right: 15px;
    -webkit-transition: 0.1s ease-in;
    -o-transition: 0.1s ease-in;
    transition: 0.1s ease-in;
}

.team4 ul li a:hover {
    -webkit-transform: translate3d(0px, -5px, 0px);
    transform: translate3d(0px, -5px, 0px);
    color: #316ce8;
}

.team-card {
    position: relative;
    border-radius: 0px !important;
    border: 6px;
    bottom: 177px;
    left: 0px;
    padding: 7px 8px 17px 4px;
    background: white;
}

.cardzindex {
    z-index: 1;
}

.statist {
    text-align: center;
    background-color: #1e3669;
    color: #ffffff;
    border-radius: 2px;
}

.statist2 {
    text-align: center;
    font-size: 18px;
}

.footerhr {
    width: 86%;
    background: white;
}

